import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Navigate, useSearchParams, useLocation } from 'react-router-dom';
import { verifySignature } from "@taquito/utils";
import { Unity, useUnityContext, unload } from "react-unity-webgl";


const Game = ({
    walletAddress = null,
    hasCartridge = null,
    unityIsLoaded = false,
}) => {
    const [searchParams, setSearchParams] = useSearchParams();

    let payload = localStorage.getItem("payload") || searchParams.get("payload")
    let publicKey = localStorage.getItem("publicKey") || searchParams.get("publicKey")
    let signature = localStorage.getItem("signature") || searchParams.get("signature")

    // chrome returns null as string. brave returns null as object
    // syncing for all browsers

    const [dataLayer, setDataLayer] = useState(null)

    if (payload === 'null') {
        payload = null
    }
    if (publicKey === 'null') {
        publicKey = null
    }
    if (signature === 'null') {
        signature = null
    }

    const validUser = payload !== null && publicKey !== null && signature !== null ? verifySignature(payload, publicKey, signature) : false

    const { unityProvider, isLoaded, loadingProgression } = useUnityContext({
        loaderUrl: `${process.env.PUBLIC_URL}/blxr_media/Blockxer_v1.loader.js`,
        dataUrl: `${process.env.PUBLIC_URL}/cb60d59f-2f29-4567-afa7-79c1f435efff/Build/Blockxer_v1.data?msg=${payload}&pk=${publicKey}&sig=${signature}`,
        frameworkUrl: `${process.env.PUBLIC_URL}/blxr_media/Blockxer_v1.framework.js`,
        streamingAssetsUrl: `${process.env.PUBLIC_URL}/blxr_media/StreamingAssets`,
        codeUrl: `${process.env.PUBLIC_URL}/cb60d59f-2f29-4567-afa7-79c1f435efff/Build/Blockxer_v1.wasm?msg=${payload}&pk=${publicKey}&sig=${signature}`,
        companyName: "Well Played Studios",
        productName: "Blockxer",
        productVersion: "1.0",
    });

    const loadingPercentage = Math.round(loadingProgression * 100);
    const [canvasHeight, setCanvasHeight] = useState("700px")
    const [canvasWidth, setCanvasWidth] = useState("1200px")
    const location = useLocation()
    const [showInstructions, setShowInstructions] = useState(false)

    useEffect(() => {
        if (loadingPercentage === 100) {
            unityIsLoaded(true)
            setTimeout(() => {
                setCanvasHeight("auto")
                setCanvasWidth("100%")
                if (dataLayer) {
                    dataLayer.push({ 'event': 'game_was_loaded' });
                }
            }, 10)
        }
    }, [loadingPercentage])

    useEffect(() => {
        const GA_dataLayer = window.dataLayer || null;
        setDataLayer(GA_dataLayer)

        return () => {
            if (window.location.pathname !== "/game") {
                window.location.replace(window.location)
            }
        }
    }, []);

    const toggleInstructions = () => {
        showInstructions ? setShowInstructions(false) : setShowInstructions(true)
    }

    return (
        <div>
            {(!payload || !publicKey || !signature || !validUser || hasCartridge === false || (walletAddress === "" || walletAddress === null)) &&
                <>
                    {unityIsLoaded && (
                        window.location = "/"
                    )
                    }

                    {!unityIsLoaded &&
                        <Navigate to="/" replace={true} />
                }
            </>
            }
            <Helmet>
                <title>Play | Blockxer</title>
            </Helmet>
            <div className={`flex flex-col justify-center pt-16 pb-16 md:overflow-hidden relative`}>
                <div className='w-full max-w-[1200px] mx-auto block bg-[#231F20] relative'>
                    {isLoaded === false && (
                        <div className="loading-overlay flex items-center" style={{ maxHeight: 700, maxWidth: 1200, width: 'calc(100vw - 88px)', height: 'calc(58.333333333333333vw - 88px)' }}>
                            <p className='w-full text-center'>Loading... ({loadingPercentage}%)</p>
                        </div>
                    )}
                    <Unity unityProvider={unityProvider} className={`w-full h-auto relative`} style={{ zIndex: 51, height: isLoaded ? canvasHeight : 0, width: isLoaded ? canvasWidth : 0 }} />
                </div>
                <div className='w-full max-w-[1200px] mx-auto block relative pt-6'>
                    <button onClick={toggleInstructions} className="float-right text-[10px] opacity-60">
                        {showInstructions &&
                            <span>Close </span>
                        }
                        Instructions
                    </button>
                    {showInstructions &&
                        <p className={` float-right text-right w-full text-[10px] opacity-60`}>Use the ‘arrow keys’ to move. Press ‘space’ to jump. Press Z, X, C to punch, kick or defend.</p>
                    }
                </div>
            </div>
        </div>
    );
};

export default Game;
