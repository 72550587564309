
import React, { useEffect, useState, useRef } from "react";

const Mouse = () => {
    const [mousePos, setMousePos] = useState({ x: -30, y: -30 });
    const pointer = useRef(null);
    const mouseLeave = () => {
        document.removeEventListener('mousemove', function (e) {
            setMousePos({
                x: -30,
                y: -30
            })
        })
    }

    const logMouseMove = (e) => {
        setMousePos({
            x: e.clientX,
            y: e.clientY
        })
    }

    useEffect(() => {
        document.body.classList.add('no-cursor')
        document.addEventListener('mousemove', function (e) {
            setTimeout(() => {
                logMouseMove(e)
            }, 100)
        })
    }, [])

    return (
        <div
            className={`pointer-events-none block h-[71px] w-[61px] fixed`}
            style={{
                background: `url(${process.env.PUBLIC_URL}/images/cursor.png) center center / contain no-repeat`,
                top: mousePos.y + "px",
                left: mousePos.x + "px",
                transform: "translate(-50%,-25%)",
                zIndex: 50
            }}
            ref={pointer}
        ></div>
    );
};

export default Mouse;