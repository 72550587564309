
import React from "react";
import { Link } from "react-router-dom";

const PrimaryButton = ({
    children,
    className = null,
    onClick = null,
    disabled = false,
    to = null,
}) => {
    return (
        <>
            {onClick &&
                <button
                    disabled={disabled}
                    onClick={onClick}
                    className={`btn-primary block mx-auto text-base  uppercase text-white  ${className} ${disabled ? 'opacity-[.65] pointer-events-none' : null}`}
                >
                    <div className={`btn-bkg z-0`}></div>
                    <div className={`bg-black py-9 px-[46px] border-2 hover:border-4 transition-all border-white z-10`}>
                        {children}
                    </div>
                </button>
            }

            {to &&
                <Link
                    disabled={disabled}
                    to={to}
                    className={`btn-primary block mx-auto text-base  uppercase text-white  ${className} ${disabled ? 'opacity-[.65] pointer-events-none' : null}`}
                >
                    <div className={`btn-bkg z-0`}></div>
                    <div className={`bg-black py-9 px-[46px] border-2 hover:border-4 transition-all border-white z-10`}>
                        {children}
                    </div>
                </Link>
            }

        </>
    );
};

export default PrimaryButton;