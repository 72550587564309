
import React, { useState, useEffect } from "react";

const Sticker = ({
    children,
    background = "#22DDF4",
    onClick = null,
    size = "lg",
    className = null,
    animateIn = false,
    willReturn = true,
    style,
}) => {
    const [animationClass, setAnimationClass] = useState(animateIn ? 'animateIn' : 'on')

    useEffect(() => {
        if (animationClass === "animateIn") {
            setTimeout(() => {
                setAnimationClass('')
            }, 10)

            setTimeout(() => {
                setAnimationClass('on')
            }, 350)
        }
    }, [animationClass])

    const peel = () => {
        setAnimationClass('peel')
        onClick()

        if (willReturn) {
            setTimeout(() => {
                setAnimationClass('animateIn')
            }, 4000)
        }
    }

    return (
        <>
            {onClick &&
                <button
                    onClick={peel}
                    style={style}
                    className={`sticker block text-base text-left uppercase text-black overflow-hidden ${size === 'lg' ? "h-[155px] w-[155px]" : "h-[60px] w-[60px]"} ${animationClass} ${className}`}
                >
                    <div className={`fold block rounded-full ${size === 'lg' ? "h-[155px] w-[155px]" : "h-[60px] w-[60px]"}`}></div>
                    <div
                        className={`sticker-content flex items-center rounded-full ${size === 'lg' ? "h-[155px] w-[155px] text-lg leading-[1.17]" : "h-[60px] w-[60px] text-center w-full text-[.5rem] leading-none"}`}
                        style={{ backgroundColor: background }}
                    >
                        {children}
                    </div>
                </button>
            }
        </>
    );
};

export default Sticker;