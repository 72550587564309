
import React from "react";

const Runner = ({ children }) => {
    return (
        <div className="w-full block bg-pink px-5  py-6 pr-12 md:py-8 md:px-20 z-10 relative">
            {children}
        </div>
    );
};

export default Runner;