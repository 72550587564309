import './App.css';
import React, { useState, useEffect } from 'react';
import Runner from './components/Runner/Runner';
import Mouse from './components/Mouse/Mouse';
import Nav from './components/Nav/Nav';
import { BeaconWallet } from "@taquito/beacon-wallet";
import { NetworkType, } from "@airgap/beacon-sdk";
import { TezosToolkit } from "@taquito/taquito";
import { verifySignature, getPkhfromPk } from "@taquito/utils";
import { Helmet } from 'react-helmet';
import {
  checkForActiveWallet,
  connectWallet,
  claimCartridge,
  checkForCartridge,
  getRPC,
  disconnectWallet,
} from './components/Wallet/Wallet';

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import Home from './pages';
import About from './pages/about';
import Game from './pages/game';
import PageNotFound from './pages/pageNotFound';
import PageNotFoundRedirect from './pages/pageNotFoundRedirect';

function App() {
  const isBrowser = () => typeof window !== "undefined"
  const [unityIsLoaded, setUnityIsLoaded] = useState(false)
  const [walletAddress, setWalletAddress] = useState('')
  const [isLoading, setIsLoading] = useState('')
  const [payload, setPayload] = useState(localStorage.getItem("payload") || "")
  const [publicKey, setPublicKey] = useState(localStorage.getItem("publicKey") || "")
  const [signature, setSignature] = useState(localStorage.getItem("signature") || "")
  const [hasCartridge, setHasCartridge] = useState(false)

  // Set the network (Mainnet is default)
  const network = { type: process.env.REACT_APP_ENV === 'development' ? NetworkType.GHOSTNET : NetworkType.MAINNET };
  const Tezos = new TezosToolkit(process.env.REACT_APP_ENV === 'development' ? getRPC(true) : getRPC(false));
  const wallet = new BeaconWallet({
    name: "Blockxer",
    preferredNetwork: network.type,
  });

  Tezos.setWalletProvider(wallet);

  // Check if we are connected to a wallet.
  useEffect(() => {
    callCheckForActiveWallet()
  }, [])

  // Check for Blockxer NFT upon connecting wallet
  useEffect(() => {
    if (walletAddress) {
      callCheckCartridge()
    }
  }, [walletAddress])

  // Update variable in local storage
  useEffect(() => {
    localStorage.setItem("payload", payload)
  }, [payload])

  useEffect(() => {
    localStorage.setItem("publicKey", publicKey)
  }, [publicKey])

  useEffect(() => {
    localStorage.setItem("signature", signature)
  }, [signature])

  useEffect(() => {
    localStorage.setItem("hasCartridge", hasCartridge)
  }, [hasCartridge])

  // User actions
  const callCheckForActiveWallet = async () => {
    if (isBrowser()) {
      const activeWallet = await checkForActiveWallet(wallet)
      if (activeWallet) {
        setWalletAddress(activeWallet)
      }
    }
  }

  const callConnectWallet = async () => {
    if (isBrowser()) {
      const walletData = await connectWallet(wallet)
      const validUser = verifySignature(walletData.payload, walletData.publicKey, walletData.signature)

      if (!validUser) return null

      setPayload(walletData.payload)
      setPublicKey(walletData.publicKey)
      setSignature(walletData.signature)
      setWalletAddress(getPkhfromPk(walletData.publicKey))
    }
  }

  const callDisconnectWallet = async () => {
    if (isBrowser()) {
      await disconnectWallet(wallet)
      setPayload(null)
      setPublicKey(null)
      setSignature(null)
      setWalletAddress(null)
    }
  }

  const callCheckCartridge = async () => {
    const cartridge = await checkForCartridge(walletAddress)
    cartridge ? setHasCartridge(true) : setHasCartridge(false)
  }

  const callClaimCartridge = async () => {
    if (isBrowser()) {
      await claimCartridge(Tezos, walletAddress, setIsLoading)
      callCheckCartridge()
      setIsLoading('Minting Complete!')
      setTimeout(() => {
        setIsLoading('')
      }, 3000)
    }
  }

  const location = useLocation();
  const [currentPath, setCurrentPath] = useState('/')
  useEffect(() => {
    setCurrentPath(location.pathname)
  }, [location?.pathname])

  return (
    <div
      className='bg-black text-white min-h-screen min-w-screen block font-start text-base leading-6 bg-cover bg-center bg-fixed overflow-x-hidden'
      style={{
        backgroundColor: 'black',
        backgroundImage: `url(${process.env.PUBLIC_URL}/images/bkg-lg.jpg)`,
      }}
    >
      <Helmet>
        <link rel="apple-touch-icon" sizes="180x180" href={`${process.env.PUBLIC_URL}/apple-touch-icon.png`} />
        <link rel="icon" type="image/png" sizes="32x32" href={`${process.env.PUBLIC_URL}/favicon-32x32.png`} />
        <link rel="icon" type="image/png" sizes="16x16" href={`${process.env.PUBLIC_URL}/favicon-16x16.png`} />

        <meta name="description" content="Blockxer is being designed as a modular, dynamic NFT-powered beat ’em up style game based on crypto memes, where players face off against street thugs, villains and rug-pullers." />

        {/* <!-- Twitter Card data --> */}
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@blockxer" />
        <meta name="twitter:title" content="Blockxer" />
        <meta name="twitter:description" content="Blockxer is being designed as a modular, dynamic NFT-powered beat ’em up style game based on crypto memes, where players face off against street thugs, villains and rug-pullers." />
        <meta name="twitter:creator" content="@blockxer" />
        {/* <-- Twitter Summary card images must be at least 120x120px --> */}
        <meta name="twitter:image" content="https://blockxer.com/meta.jpg" />

        {/* <!-- Open Graph data --> */}
        <meta property="og:title" content="Blockxer" />
        <meta property="og:url" content="https://blockxer.com/" />
        <meta property="og:image" content="https://blockxer.com/meta.jpg" />
        <meta property="og:description" content="Blockxer is being designed as a modular, dynamic NFT-powered beat ’em up style game based on crypto memes, where players face off against street thugs, villains and rug-pullers." />
        <meta property="og:site_name" content="Blockxer" />
      </Helmet>
      {/* Overlay div */}
      <div
        className={`pointer-events-none fixed top-0 left-0 h-screen w-screen bg-black z-0 transition-opacity duration-700
          ${(walletAddress && hasCartridge) || currentPath === "/about" || currentPath === "/pageNotFound" ? "opacity-[.65]" : "opacity-0"}
          ${currentPath === "/game" ? "opacity-100" : ""}
        `}
      ></div>

      <Runner>
        <h2 className={`text-xs md:text-base leading-6 text-left md:text-center`}>
          <div className={`inline-block relative task ${walletAddress ? 'task-checked' : null}`}>Link your wallet.</div>&nbsp;
          <div className={`inline-block relative task ${walletAddress && hasCartridge ? 'task-checked' : null}`}>Claim a cartridge.</div>&nbsp;
          <div className={`inline-block relative task ${walletAddress && hasCartridge ? 'task-pumped' : null}`}>Join the fight.</div>
        </h2>
      </Runner>
      <div className='px-7 md:px-11 mx-auto max-w-[1400px] z-10 relative'>
        <Nav walletAddress={walletAddress} callDisconnectWallet={callDisconnectWallet} payload={payload} publicKey={publicKey} signature={signature} hasCartridge={hasCartridge} unityIsLoaded={unityIsLoaded} />
        <Routes>
          <Route exact path='/'
            element={
              <Home
                tezos={Tezos}
                wallet={wallet}
                walletAddress={walletAddress}
                payload={payload}
                publicKey={publicKey}
                signature={signature}
                hasCartridge={hasCartridge}
                isLoading={isLoading}
                callConnectWallet={callConnectWallet}
                callDisconnectWallet={callDisconnectWallet}
                callClaimCartridge={callClaimCartridge}
              />
            }
          />
          <Route path='/about' element={<About />} />
          <Route path='/game'
            element={
              <Game
                walletAddress={walletAddress}
                hasCartridge={hasCartridge}
                unityIsLoaded={setUnityIsLoaded}
              />
            }
          />
          <Route path="/pageNotFound" element={<PageNotFound />} />
          <Route path="*" element={<PageNotFoundRedirect />} />
        </Routes>
        <footer className={`text-xs text-white relative md:absolute md:bottom-1.5 md:left-0 px-3.5 pb-6 ms:pb-0 font-public w-full table`}>
          <p className={'w-full text-center md:w-auto md:text-left md:float-left'}>Made with care by <a className={`underline`} href={`https://blokhaus.io/`} target={`_blank`}>Blokhaus</a></p>
          <p className={'w-full text-center md:w-auto md:text-right md:float-right'}>Powered by <a className={`underline`} href={`https://api.tzkt.io/`} target={`_blank`}>TzKT API</a></p>

        </footer>
        <Mouse />
      </div>
    </div>
  );
}

function root() {
  return <Router>
    <App />
  </Router>
}

export default root;
