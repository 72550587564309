import React from 'react';
import { Helmet } from 'react-helmet';

const About = () => {
    return (
        <div>
            <Helmet>
                <title>About | Blockxer</title>
            </Helmet>
            <div className={`w-full mx-auto max-w-[1180px]`}>
                <div className={`text-center text-base font-zen mt-0 md:mt-10 mb-20`}>
                    <h2 className='mb-6'>Blockxer is being designed as a modular, dynamic NFT-powered beat ’em up style game based on crypto memes, where players face off against street thugs, villains and rug-pullers. Currently in beta mode, the game is envisioned to allow future levels, characters and more to be community created and shared as NFT packs that dynamically build upon connecting the wallet. In Blockxer, everything will be an NFT. Your hero character? An NFT. The backdrop to the epic fighting scene? Also an NFT. Even the rusty knife you pick up on the street to fight villains with – you guessed it, an NFT.</h2>
                    <p className='mb-6'>The game will work by loading the NFTs in your wallet as either character sprites or game elements in the game. For the demo level, the game is unlocked by claiming a free cartridge NFT to allow early access to fans of the concept. In the future, Blockxer will allow players to create their own characters, backdrops and weapons to make it even more fun and interactive, allowing players to completely customize the game to their liking.</p>
                </div>
                <div className={`text-center text-base font-zen mt-10 pb-20`}>
                    <h3 className='text-[28px] md:text-[38px] font-start mb-16 leading-relaxed'>“Critical” Acclaim for Blockxer</h3>
                    <div className={`block md:flex md:gap-4 md:px-12`}>
                        <p className='mb-12 w-full'>“The most frustrating game I have ever played. This game ruined my life and the lives of my four betta fish.”</p>
                        <p className='mb-12 w-full md:w-3/4'>“I hate it here.”</p>
                        <p className='mb-12 w-full'>“Whoever made this game has something terrible coming for them.”</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default About;