import React from 'react';
import { Helmet } from 'react-helmet';
import PrimaryButton from '../components/Buttons/Primary';
import Sticker from '../components/Sticker/Sticker';


const Home = ({
    walletAddress,
    payload,
    publicKey,
    signature,
    hasCartridge,
    isLoading,
    callConnectWallet,
    callClaimCartridge,
}) => {
    return (
        <>
            <Helmet>
                <title>Blockxer</title>
            </Helmet>

            <div className={`flex flex-col justify-center pt-12 md:pt-0 pb-32 overflow-hidden relative w-screen -ml-7 px-7`} style={{ height: `calc(100vh - 228px)`, maxWidth: '1284px' }}>
                {/* Cartridge container */}
                <div className={`
                    main-cartridge absolute block left-1/2 -translate-x-1/2 w-full max-w-[430px] transition-all duration-700
                    ${!walletAddress ? 'top-full -translate-y-full' : null}
                    ${walletAddress && !hasCartridge ? 'top-1/2 -translate-y-[250px]' : null}
                    ${walletAddress && hasCartridge ? 'top-full translate-y-full' : null}
                    ${walletAddress && payload && publicKey && signature && !hasCartridge ? 'cursor-pointer' : ''}
                    `}
                    onClick={() => {
                        if (walletAddress && payload && publicKey && signature && !hasCartridge) {
                            callClaimCartridge()
                        }
                    }}
                >
                    <img className='w-full h-auto' src={`${process.env.PUBLIC_URL}/images/cartridge.png`} alt="Blockxer Cartridge" />
                </div>

                {/* State 1: No wallet and/or signed payload */}
                {(!walletAddress || !payload || !publicKey || !signature) &&
                    <div className={`relative`}>
                        <PrimaryButton onClick={callConnectWallet}>
                            Link a wallet to play
                        </PrimaryButton>
                        <Sticker background='#F34246' onClick={() => { return null }} animateIn className={`left-3/4 bottom-[80%] absolute md:hidden`}>
                            <span className='block w-full text-center text-sm'>Desktop only</span>
                        </Sticker>
                    </div>
                }

                {/* State 2: Has connected wallet and signed payload, but no Cartridge NFT */}
                {(walletAddress && payload && publicKey && signature && !hasCartridge) &&
                    <div className={`translate-y-[180px] relative table mx-auto`}>
                        <PrimaryButton disabled={isLoading} onClick={callClaimCartridge}>
                            {isLoading === '' &&
                                <>next: claim your beta cartridge</>
                            }
                            {isLoading !== '' &&
                                <>{isLoading}</>
                            }
                        </PrimaryButton>
                        <Sticker onClick={callClaimCartridge} animateIn className={`absolute right-0 bottom-[78%]`}>
                            Claim<br />your<br />nft!
                        </Sticker>
                    </div>
                }

                {/* State 3: Has connected wallet, signed payload, and Cartridge NFT */}
                {(walletAddress && payload && publicKey && signature && hasCartridge) &&
                    <PrimaryButton to={`/game?payload=${payload}&publicKey=${publicKey}&signature=${signature}`}>
                        Play
                    </PrimaryButton>
                }
            </div>
        </>
    );
};

export default Home;
