
import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Sticker from "../Sticker/Sticker";

const Nav = ({
    walletAddress,
    callDisconnectWallet,
    payload,
    publicKey,
    signature,
    hasCartridge,
    unityIsLoaded,
}) => {
    const listStyles = "inline-block"
    const linkStyles = "nav-link block text-sm lg:text-base pt-2.5 px-2.5 pb-6 ml-10"
    const mobileListStyles = "block mb-3.5 w-full"
    const mobileLinkStyles = "btn-primary block mx-auto text-base  uppercase text-white bg-black py-9 px-[46px] border-2 w-full text-center"
    const [isMobileOpen, setIsMobileOpen] = useState(false)
    const location = useLocation()
    const discordURL = "https://discord.gg/SfK4ZSWrNA"

    const toggleMobileNav = () => {
        isMobileOpen ? setIsMobileOpen(false) : setIsMobileOpen(true)
    }

    useEffect(() => {
        setIsMobileOpen(false)
    }, [location])


    return (
        <nav className={`flex pt-2.5 md:pt-0`}>
            {/* quick hack to force page reload if unity has been loaded */}
            {/* otherwise the game will error out and continue to play in the background */}
            {/* look into proper unloading of untiy post launch */}
            {!unityIsLoaded &&
                <>
                <Link to={`/`} className={`block mx-auto md:ml-0 w-1/2 max-w-[307px] relative`}>
                    <h1>
                        <img src={`${process.env.PUBLIC_URL}/images/blockxer-logo.png`} alt="Blockxer Logo" />
                    </h1>
                    <Sticker background="#F54136" size="sm" className={`absolute -right-[40px] bottom-[5px] hidden md:block`} animateIn onClick={() => { return null }}>
                        <span className="pl-1">Beta</span>
                    </Sticker>
                </Link>
                <button className={`mobile-nav-btn block md:hidden z-50 ${isMobileOpen ? 'on' : null}`} onClick={toggleMobileNav}><span className={`sr-only`}>Toggle Mobile Menu</span></button>
                {isMobileOpen &&
                    <div className={`flex flex-row items-center md:hidden fixed w-screen h-screen top-0 left-0 bg-pink z-40`}>
                        <ul className="flex flex-col items-center w-full px-14">
                            {walletAddress &&
                                <li className={mobileListStyles}>
                                    <button className={mobileLinkStyles} onClick={() => {
                                        setIsMobileOpen(false)
                                        callDisconnectWallet()
                                    }}>
                                        Log Out
                                    </button>
                                </li>
                            }
                            <li className={mobileListStyles}>
                                <Link className={mobileLinkStyles} to="/about">
                                    About
                                </Link>
                            </li>
                            {location.pathname !== '/game' &&
                                <li className={mobileListStyles}>
                                    <Link className={mobileLinkStyles} to={payload && publicKey && signature && hasCartridge ? "/game" : "/"}>
                                        Play
                                    </Link>
                                </li>
                            }
                            <li className={mobileListStyles}>
                                <a className={mobileLinkStyles} target={"_blank"} href={discordURL}>
                                    Join Discord
                                </a>
                            </li>
                        </ul>
                    </div>
                }
                <ul className="hidden ml-auto md:flex flex-row items-center">
                    {walletAddress &&
                        <li className={listStyles}>
                            <button className={linkStyles} onClick={callDisconnectWallet}>
                                Log Out
                            </button>
                        </li>
                    }
                    <li className={listStyles}>
                        <Link className={linkStyles} to="/about">
                            About
                        </Link>
                    </li>
                    {location.pathname !== '/game' &&
                        <li className={listStyles}>
                            <Link className={linkStyles} to={payload && publicKey && signature && hasCartridge ? "/game" : "/"}>
                                Play
                            </Link>
                        </li>
                    }
                    <li className={listStyles}>
                        <a className={linkStyles} target={"_blank"} href={discordURL}>
                            Join Discord
                        </a>
                    </li>
                </ul>
                </>
            }

            {unityIsLoaded &&
                <>
                    <a href={`/`} className={`block mx-auto md:ml-0 w-1/2 max-w-[307px] relative`}>
                        <h1>
                            <img src={`${process.env.PUBLIC_URL}/images/blockxer-logo.png`} alt="Blockxer Logo" />
                        </h1>
                        <Sticker background="#F54136" size="sm" className={`absolute -right-[40px] bottom-[5px] hidden md:block`} animateIn onClick={() => { return null }}>
                            <span className="pl-1">Beta</span>
                        </Sticker>
                    </a>
                <button className={`mobile-nav-btn block md:hidden z-50 ${isMobileOpen ? 'on' : null}`} onClick={toggleMobileNav}><span className={`sr-only`}>Toggle Mobile Menu</span></button>
                {isMobileOpen &&
                    <div className={`flex flex-row items-center md:hidden fixed w-screen h-screen top-0 left-0 bg-pink z-40`}>
                        <ul className="flex flex-col items-center w-full px-14">
                            {walletAddress &&
                                <li className={mobileListStyles}>
                                    <button className={mobileLinkStyles} onClick={() => {
                                        setIsMobileOpen(false)
                                        callDisconnectWallet()
                                    }}>
                                        Log Out
                                    </button>
                                </li>
                            }
                            <li className={mobileListStyles}>
                                <a className={mobileLinkStyles} href="/about">
                                    About
                                </a>
                            </li>
                            {location.pathname !== '/game' &&
                                <li className={mobileListStyles}>
                                    <a className={mobileLinkStyles} href={payload && publicKey && signature && hasCartridge ? "/game" : "/"}>
                                        Play
                                    </a>
                                </li>
                            }
                            <li className={mobileListStyles}>
                                <a className={mobileLinkStyles} target={"_blank"} href={discordURL}>
                                    Join Discord
                                </a>
                            </li>
                        </ul>
                    </div>
                }
                    <ul className="hidden ml-auto md:flex flex-row items-center">
                        {walletAddress &&
                            <li className={listStyles}>
                                <button className={linkStyles} onClick={callDisconnectWallet}>
                                    Log Out
                                </button>
                            </li>
                        }
                        <li className={listStyles}>
                            <a className={linkStyles} href="/about">
                                About
                            </a>
                        </li>
                        {location.pathname !== '/game' &&
                            <li className={listStyles}>
                                <a className={linkStyles} href={payload && publicKey && signature && hasCartridge ? "/game" : "/"}>
                                    Play
                                </a>
                            </li>
                        }
                        <li className={listStyles}>
                        <a className={linkStyles} target={"_blank"} href={discordURL}>
                                Join Discord
                            </a>
                        </li>
                    </ul>
                </>
            }

        </nav>
    );
};

export default Nav;