import React, { useState, useEffect } from 'react';
import Sticker from '../components/Sticker/Sticker';

const words = [
    "Go away",
    "Hi mom!",
    "Help?",
    "is this DLC?",
    "Where'd I leave my wallet",
    "Trick or treat?",
    "Where's the beef?",
    "turn off/on",
]

const colors = [
    "#F54136",
    "#F34246",
    "#fdf858",
    "#74f297",
    "#72a0f7",
]

function randomIntFromInterval(min, max) { // min and max included 
    return Math.floor(Math.random() * (max - min + 1) + min)
}

function createRandomSticker() {
    let data = []
    for (let i = 0; i < 40; i++) {
        data.push({
            'text': words[randomIntFromInterval(0, words.length - 1)],
            'posX': randomIntFromInterval(0, 100),
            'posY': randomIntFromInterval(0, 100),
            'rotation': randomIntFromInterval(-30, 30),
            'size': randomIntFromInterval(0, 1) ? 'sm' : 'lg',
            'color': colors[randomIntFromInterval(0, colors.length - 1)]
        })
    }

    return data
}

const PageNotFound = () => {
    const [randomSticker, setRandomStickers] = useState([])
    useEffect(() => {
        setRandomStickers(createRandomSticker())
    }, [])

    return (
        <>
            <div className={`flex flex-col justify-center pb-16 md:overflow-hidden relative`} style={{ height: `calc(100vh - 228px)` }}>
                <h1 className={`text-[20vw] 2xl:text-[300px] w-full text-center pointer-events-none`}>404</h1>
                {randomSticker.map((sticker, i) => {
                    const leftPos = `calc(${sticker.posX}% ${sticker.posX > 50 ? '- 155px' : '+ 21px'})`
                    const topPos = `calc(${sticker.posY}% ${sticker.posY > 50 ? '- 200px' : '+ 10px'})`
                    return (
                        <Sticker
                            onClick={() => { return null }}
                            willReturn={false}
                            background={sticker.color}
                            key={i}
                            className={`absolute`}
                            style={{ top: topPos, left: leftPos, zIndex: i }}
                        // size={sticker.size}
                        >
                            {sticker.text}
                        </Sticker>
                    )
                })}
            </div>
        </>
    )
}
export default PageNotFound;