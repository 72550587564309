import { SigningType } from "@airgap/beacon-sdk";
import { char2Bytes } from "@taquito/utils";
import { NetworkType } from "@airgap/beacon-sdk";
import axios from "axios";

export async function requestPayload(wallet, pk) {
    try {
        const payload = '050100000042' + char2Bytes(`Tezos Signed Message: Sign in to Blockxer ${new Date().toISOString()}`)
        const response = await wallet.requestSignPayload({
            signingType: SigningType.MICHELINE,
            payload: payload,
        });

        const walletData = {
            publicKey: pk,
            payload: payload,
            signature: response.signature
        }

        return walletData
    } catch (err) {
        console.error(err)
    }
}

export async function connectWallet(wallet) {
    try {
        const activeAccount = await wallet.client.getActiveAccount();
        if (activeAccount) {
            // User already has account connected
            return requestPayload(wallet.client, activeAccount.publicKey)
        } else {
            // Log user into a wallet
            const permissions = await wallet.client.requestPermissions({
                network: {
                    type: process.env.REACT_APP_ENV === 'development' || process.env.REACT_APP_ENV === 'staging' ? NetworkType.GHOSTNET : NetworkType.MAINNET,
                },
            });
            return requestPayload(wallet.client, permissions.publicKey)
        }
    } catch (err) {
        console.error(err)
    }
}

export async function disconnectWallet(wallet) {
    await wallet.clearActiveAccount();
    localStorage.clear();
}

export async function checkForActiveWallet(wallet) {
    const activeAccount = await wallet.client.getActiveAccount();
    if (activeAccount) {
        return activeAccount.address
    }

    return false
}

export async function claimCartridge(Tezos, address, loadingText) {
    const CLAIM_CARTRIDGE_CONTRACT = (process.env.REACT_APP_ENV === 'development' || process.env.REACT_APP_ENV === 'staging') ? 'KT19wsscAXRN7D8oGQ779f7G2ZSa33Sn5kfQ' : 'KT1NjMYSVnfrTiuKEKsyXp61hnWP3CL6qPW2';
    const contract = await Tezos.wallet.at(CLAIM_CARTRIDGE_CONTRACT);
    loadingText('Request in Progress')

    const tokenId = "0";
    try {
        const result = await contract.methods.mint_tokens([0]).send();
        loadingText('Minting. This might take a minute.')
        await result.confirmation()
    } catch (error) {
        console.error(error);
    }
}

export async function checkForCartridge(address) {
    try {
        const network = process.env.REACT_APP_ENV === 'development' || process.env.REACT_APP_ENV === 'staging' ? 'ghostnet' : 'mainnet'
        const contract = process.env.REACT_APP_ENV === 'development' || process.env.REACT_APP_ENV === 'staging' ? 'KT19wsscAXRN7D8oGQ779f7G2ZSa33Sn5kfQ' : 'KT1NjMYSVnfrTiuKEKsyXp61hnWP3CL6qPW2'
        const url = `https://api.${network}.tzkt.io/v1/tokens/balances?token.contract=${contract}&token.tokenId=0&account=${address}`

        const response = await axios.get(url)

        // check response data
        if (
            response.data[0]?.token.contract.address === contract &&
            parseInt(response.data[0]?.token.tokenId) === 0 &&
            parseInt(response.data[0]?.balance) > 0
        ) {
            return true
        } else {
            return false
        }

    } catch (error) {
        console.error(error)
    };
}

export function getRPC(testnet) {
    const ghostnetRPCs = [
        "https://ghostnet.tezos.marigold.dev/",
    ]

    const mainnetRPCs = [
        "https://mainnet.api.tez.ie",
    ]

    if (testnet) {
        return ghostnetRPCs[randomIntFromInterval(0, ghostnetRPCs.length - 1)]
    } else {
        return mainnetRPCs[randomIntFromInterval(0, mainnetRPCs.length - 1)]
    }
}

function randomIntFromInterval(min, max) { // min and max included 
    return Math.floor(Math.random() * (max - min + 1) + min)
}